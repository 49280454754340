import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import React, {useState,useEffect} from 'react';
import {componentDidMount} from './location';
import { func } from 'prop-types';
import UserLocation from './geo.js'
function App() {

/* https://cors-anywhere.herokuapp.com/corsdemo
Proxy server */

const apiKey = process.env.REACT_APP_YELP_KEY;
const location = UserLocation()


const [optionLatLongData , setOptionLatLongData] = useState([])
const [optionUserData , setOptionUserData] = useState()
const [firstUpdate, setFirstupdate] = useState(true)

const [restaurant , setRestaurant] = useState('')

const [lat, setLat] = useState('')
const [long, setLong] = useState('')

const [userLocation, setUserLocation] = useState('')

const [islatLong , setIsLatLong] = useState(true)
const [isuserData , setIsuserData] = useState(true)

function changeStateLatLong() {
  setIsLatLong(!islatLong);
}
function changeStateUserData() {
  setIsuserData(!isuserData);
}


 
function yelpLatLong(x,y){
  


    
    const options = {
      method: 'GET',
      url: 'https://api.yelp.com/v3/businesses/search',
      params: {sort_by: 'distance', limit: '20', term: 'restaurants',
      // location: '4000 Market Pl Dr, Monterey Park, CA 91755',
      latitude :  x ,
      longitude: y ,
      radius: 8049},
      headers: {accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,}
    }
  
    
    return options
  
}

function yelpLocation(detail){
  


    
  const options = {
    method: 'GET',
    url: 'https://api.yelp.com/v3/businesses/search',
    params: {sort_by: 'distance', limit: '20', term: 'restaurants',
    location: detail,
    
    radius: 8049},
    headers: {accept: 'application/json',
    Authorization: `Bearer ${apiKey}`,}
  }

  
  return options

}




const handleClickLocation = () => {
  // changeStateUserData()
if(!location.loaded ) {
    console.log('location not available')
  }
  else{
    return setLat(location.coordinates.lat), setLong(location.coordinates.long),changeStateLatLong()  }
  if(isuserData === true){
    setIsuserData(false)

  }
  };
        
  

  useEffect(() => {
  
    if( lat ===''){
      console.log("NO LAT LONG coordinates detected")
    }
    else{
    axios.request(yelpLatLong(lat ,long)).then((response)=>{
      return setOptionLatLongData(response.data.businesses) ,setFirstupdate(false)
    })}
  }
  , [lat,long])




const searchLocation = (event) =>{
  if(event.key === 'Enter'){
    
    
   if(validAddress.test(userLocation)){
    


    axios.request(yelpLocation(userLocation)).then((response)=>{
      setOptionUserData(response.data.businesses)
    })
    setUserLocation('')
    changeStateLatLong()
    
   }
   else{
     setUserLocation("enter valid address")
   }

  }}

const validAddress = new RegExp(
  "[0-9]{1,5}( [a-zA-Z.]*){1,4},?( [a-zA-Z]*){1,3},? [a-zA-Z]{2},? [0-9]{5}"
);




// if(lat === 'undefined')return console.log("error block")



// console.log(location.loaded)

  return (
    
    <div className="App">
      <header className="App-header">
        <div className="card text-bg-dark mb-3 border-secondary" style={{ width: '18rem'}}>
          <div className="card-body">
            <h1 className="card-title">Food Finder</h1>
            <p className="card-text">20 Nearby Food Locations</p>
          </div>
          <ul className="list-group list-group-flush list-unstyled"  >
          <li className="list-group-item-dark"><button className="btn btn-primary"onClick={handleClickLocation} type="button">Find Nearby Food Restaurants</button></li>
          <li className="list-group-item-dark">OR</li>
          <li className="list-group-item-dark "> <input
              value={userLocation}
              onChange={event => setUserLocation(event.target.value)}
              placeholder="Enter Address"
              onKeyPress={searchLocation}
              type="text"
              /></li>
              {userLocation && (<p className="text-danger">You just typed: {userLocation}</p>)}
           {/* <li className="list-group-item-dark"><button className="btn btn-primary"onClick={} type="button">Clear</button></li> */}

          </ul>
           
          <div className="card-body">
        
          
          </div> 
          
          </div>


        
     

       

        
        {islatLong ? optionLatLongData && optionLatLongData.map(item => <div key={item.id}>
          
          <div className="card text-bg-dark mb-3 border-light" style={{ width: '18rem'}}>
          <img className="card-img-top img-thumbnail" src={item.image_url} alt="Yelp Image"/>
          <div className="card-body">
            <h5 className="card-title">{item.name}</h5>
            <p className="card-text">{item.categories[0].title}</p>
          </div>
          <ul className="list-group list-group-flush list-unstyled"  >
          <li className="list-group-item-dark">Open for business: {!item.is_closed ? "Yes" : "No"}</li>
            <li className="list-group-item-dark "><a className ="link-secondary"href={item.phone} >Phone Number</a></li>
            <a  className ="link-secondary"href={"http://maps.google.com/?q=" + item.location.display_address} >Address</a>
          </ul>
          <div className="card-body">
            <a href={item.url} className="card-link link-secondary">Yelp Link</a>
          </div>
          </div>

            </div>
            
        ):null}
 
        

         



         
        {/* Input box area */}

         

      {isuserData ? optionUserData && optionUserData.map(item => <div key={item.id}>
          
          <div className="card text-bg-dark mb-3 border-primary" style={{ width: '18rem'}}>
          <img className="card-img-top" src={item.image_url} alt="Yelp Image"/>
          <div className="card-body">
            <h5 className="card-title">{item.name}</h5>
            <p className="card-text">{item.categories[0].title}</p>
          </div>
          <ul className="list-group list-group-flush list-unstyled"  >
          <li className="list-group-item-dark">Open for business: {!item.is_closed ? "Yes" : "No"}</li>
            <li className="list-group-item-dark "><a className ="link-secondary"href={item.phone} >Phone Number</a></li>
            <a  className ="link-secondary"href={"http://maps.google.com/?q=" + item.location.display_address} >Address</a>
          </ul>
          <div className="card-body">
            <a href={item.url} className="card-link link-secondary">Yelp Link</a>
          </div>
          </div>

            </div>
            
            
            
        ): null}
       
      </header>
    </div>
  );
}

export default App;
